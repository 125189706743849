import React from "react";
import { Stack, Main } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import AuthorExpanded from "@widgets/AuthorExpanded";
import { useBlogAuthors } from "@helpers-blog";

export default () => {
  const authors = useBlogAuthors();

  return (
    <>
      <Seo title="Our Team" />
      <Divider />
      <Stack effectProps={{ effect: "fadeInDown" }}>
        <PageTitle
          header="Meet the Team"
          subheader="Learn from top tier engineers with over a decade of practical coding experience"
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map(author => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
        </Main>
      </Stack>
    </>
  );
};
